@media screen and (min-width: 600px) {


    .logonav {
        width: 180px;
        height: 60px;
        background-size: contain;
        display: none;
       /* -webkit-filter: brightness(0) invert(1);*/
      /*filter: brightness(0) invert(1);*/
    }
    .navcontainer1 {
        z-index: 300;
        height: 46px;
        width: 100%;
        position: fixed;
        top: 88px;
        transition: top 0.3s;
        /*box-shadow: 0 2px 6px 0 rgba(0,0,0,.7);*/
        background-color: #101010;
       
        
        
        
       
    }
    .flexnav {
        display: flex;
        flex-direction: row;
        justify-items: auto;
        gap: 35px;
        background-color: #101010;
        margin: 0 auto;
        
    }
    .navcontainer2 {
        z-index: 300;
        height: 43px;
        width: 100%;
        position: fixed;
        top: -60px;
        transition: top 0.4s;
        background-color: #101010;
       
        
        
        
    
    }
    .linksnav,.linksnav:after,.linksnav:before {
        transition: all .5s;
    }
    .linksnav {
        color: #FDF7F4 !important;
        position:relative;
        z-index: 1;
       
        font-size: 0.75em;
        font-weight: 600;

        
        
    }
    .linksnav:hover {
        color: white!important;
        
        font-weight: 650;
    }
    .linksnav:after {
        display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 1px;
      content: '.';
      color: transparent;
      background: #178582;
      border-radius: 5px;
      visibility: none;
      opacity: 0;
      z-index: -1;
    }
    .linksnav:hover:after {
        opacity: 1;
      visibility: visible;
      height: 100%;
    }
    #sprt {
        display: block;
        border: 2px solid #178582;
        color: whitesmoke!important;
        border-radius: 10px;
    }
    #basic-nav-dropdown {
        color: #FDF7F4 !important;
        
    
    }
    .dropdown-menu {
        background-color: rgb(14,17,17);
        color: white!important;
        
        padding: 15px 10px;
        
    }
    .dropdown-item {
        color: white;
        font-size: 0.8em;
        font-weight: 600;
        margin-bottom: 5px;
        border-bottom: 2px solid #272727;
    }
    .dropdown-item:hover {
        font-size: 0.8em;
        background-color: #FDF7F4;
        color: black;
        font-weight: 600;
    }
    #basic-nav-dropdown:hover {
        
            color: white!important;
        
    }
    .dropbg:hover {
        background-color: black!important;
    }
    }
    @media screen and (max-width:600px) {
        .navcontainer1{
            background-color: rgb(14,17,17);
            height: 100px;
            z-index: 50000;
            padding: 0px;
            
            
        
        }
        .containerm {
            padding: 0px;
            margin: 0 auto;
        }
        .flexnav {
            background-color: #141717;
            width: 100%;
           padding-left: 30px;
           padding-right: 30px;
           padding-bottom: 20px;
           border-bottom-left-radius: 10px;
           border-bottom-right-radius: 10px;
           box-shadow: 0px 3px 10px rgb(201, 201, 201);

           
        }
        .logonav {
            height: 80px;
            width: 200px;
            -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
      margin-left: 10px;
     
        }
        
        .togglebtn {
            height: 40px;
            width: 50px;
            display: flex;
            justify-content: center;
            position: relative;
            left: -10px;
            filter: invert(1);
            border: none;
            
        }
        .navbar-toggler  {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: rgba(255, 255, 255, 0.07) 0px 1px 2px, rgba(255, 255, 255, 0.07) 0px 2px 4px, rgba(255, 255, 255, 0.07) 0px 4px 8px, rgba(255, 255, 255, 0.07) 0px 8px 16px, rgba(255, 255, 255, 0.07) 0px 16px 32px, rgba(255, 255, 255, 0.07) 0px 32px 64px;
        }
        .navbar-toggler-icon {
            width: 40px;
            height: 5px;
           
            width: 2em;
            height: 2em;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAYFBMVEX///9AQEAoKCj09PQ9PT0vLy8sLCw6OjpUVFTd3d3Jycl4eHg1NTUpKSn5+fk4ODgiIiJcXFydnZ1+fn7Pz8/t7e3k5OS5ubmIiIhwcHDg4OBLS0tFRUWSkpKurq5iYmK5pxemAAACUElEQVR4nO3dCXKrMBBFUQkL4UHMGOyM+99l7Pyqv4Pul5B7dnALyg7tRgkBAAAAAAAAAAAAAAAAAABArzt46zzzlrlU/sq8OPVdx7aJCk07Xj0CT6K+f40n+8Brm2SBMabW/iqOuiv41IzWgUsrDYyxtf64mbWX8HERZ+PCIg6MsdgGdpU6MFa2X/2HH1B4oJBCCtUopJBCCn994f7/agtJ+fz7lJJtYLj14sL+Zlx4Ut+mlfmk5q69iP3dOjB0vXQS1TsMhq+N7jm/JJeBaTcPRXEdUxmsZzT/rdNYcu0rl3FavQIBAAAAAMBudOvJ2+q5frmNVfZXjZtT3ynWmpFpqqPDamII50E3Ek7D2T7wZZD1PQ0v5oWv2p/X0qt14DlLA2PM1vfpRb59eTEurMWBMda2gfv/HX//uxgUUkghhRRSSOFfKAw/oNA2MLzJty/fjAvf5duX78aFq3aIEeNgvhh1076fV6wXaB9elU/5jfmY5qE76p7z66PP4Huq6ib5a+pqcul76LZ5PHob58314AgAAAAAALAL63L2tji+QdpNfev8juxT209Og5pzVg2Fi/nK17cP5Vx/+LAPXMTbl/antDbi7Uvro03Cpl7dq62Xve/y7Uvroz/UC7QxZttAti89Ctk2oZBCdSCFFFJI4e8v/APbl+KX8xxez5vUB+sX672oq3z70vxcwUn7kF87rLZ9Ku/T8mkfGMI9qz5tUrY/vfTblnJp/JWcvI4cCGGZ5ou3efL6h1YAAAAAAAAAAAAAAAAAAAAAAAD79gWaO1Pdj84d6wAAAABJRU5ErkJggg==');
            background-size: cover;
            background-repeat: no-repeat;
            
            
            
        }
        .linksnav {
            color: white !important;
            padding-left: 20px;
            border-bottom: 0.13em solid rgb(26, 25, 25);
            margin-bottom: 10px;
            
        }
        .dropdown-menu {
            background-color: rgb(14,17,17);
            color: white!important;
            
            padding: 15px 10px;
            
        }
        .dropdown-item {
            color: white;
            font-size: 0.93em;
            font-weight: 600;
            margin-bottom: 5px;
            border-bottom: 2px solid #272727;
        }
        .dropdown-item:hover {
            font-size: 0.93em;
            background-color: #FDF7F4;
            color: black;
            font-weight: 600;
        }
        
        #basic-nav-dropdown {
            color: white !important;
           
            
        
        }
        #sprt {
            display: block;
            background-color: #fff;
            color: black!important;
            border-radius: 10px;
            font-weight: 700;
        }
        
    }