@media screen and (min-width:600px) {
    .aboutteampagecon {
        width: 100%;
        height: 530px;
        
       display: flex;
       
       padding: 50px 70px 50px 100px;
       gap: 40px;
       position: relative;
      
       
    }
    .wave {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 70%;
        background-color: #F3F5FA;
        border-top-left-radius: 100%;
        border-top-right-radius: 10%;
        left: 0px;
        bottom: 0px;

    }
    .leftsidecon {
        z-index:10;
        width: 45%;
        height: 100%;
       
        padding: 30px 0px 50px 0px;
        
    }
    
    .headingleftsidecon {
        font-size: 1em;
        font-weight: 550;
        font-family: 'oswald',sans-serif;
       
        /*text-shadow: -1px 1px 2px rgb(165, 163, 163),
				  1px 2px 6px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;*/

                 
                  position: relative;
        padding-top: 5px;
        border-left:3px solid black;
        padding-left: 7px;

    }
   
    .descriptionleftsidecon {
        padding-top: 20px;
        font-size: 0.95em;
        font-weight: 500;
        padding-left: 5px;
    }

    .rightsidecon {
        z-index: 10;
        width: 55%;
        height: 100%;
       
        padding: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .rightsideimg {
        width: 90%;
        height: 90%;
        
        background-size: cover;
        margin: 0 auto;
        z-index: 2;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }
    .dottedbg {
        position: absolute;
        width: 150px;
        height: 150px;
        background-image: url("../../../../public//assets/bgdotted.webp");
        background-size: cover;
        top: 5%;
        right: 5%;
        z-index: -1;

    }
    .dottedbg2 {
        position: absolute;
        width: 150px;
        height: 150px;
        background-image: url("../../../../public//assets/bgdotted.webp");
        background-size: cover;
        bottom: 5%;
        left: 5%;
        z-index: -1;

    }
    .firstpic {
        width: 370px;
        height: 220px;
        background-color: black;
        position: relative;
        left: -150px;
        border-radius: 10px;
    
    }
    .secondpic {
        width: 370px;
        height: 220px;
        background-color: black;
        position: relative;
        top: -50px;
        border-radius:10px ;
    }

}

@media screen and (max-width:600px){
    .aboutteampagecon {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px;
        
    }
    .wave {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 80%;
        background-color: #F3F5FA;
        border-top-left-radius: 100%;
        border-top-right-radius: 50%;
        left: 0px;
        bottom: 0px;

    }
    .leftsidecon {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        z-index: 10;
    }
    .headingleftsidecon {
        font-size: 1em;
        font-weight: 550;
        font-family: 'oswald',sans-serif;
       
        /*text-shadow: -1px 1px 2px rgb(165, 163, 163),
				  1px 2px 6px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;*/

                 
                  position: relative;
        padding-top: 5px;
        border-left:3px solid black;
        padding-left: 7px;
        margin-bottom: 10px;

    }
    .descriptionleftsidecon {
        font-size: 0.9em;
        font-weight: 500;
        color:#333333;
    }
    .rightsidecon {
        width: 90%;
        height: 300px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
        z-index: 10;
       
    }
    .rightsideimg {
        width: 90%;
        height: 90%;
        background-image: url("../../../../public//assets/zfr4.0.jpg");
        background-size: cover;
        margin: 0 auto;
        z-index: 2;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }
    .dottedbg {
        position: absolute;
        width: 150px;
        height: 150px;
        background-image: url("../../../../public//assets/bgdotted.webp");
        background-size: cover;
        top: 10%;
        right: 0%;
        z-index: -1;

    }
    .dottedbg2 {
        position: absolute;
        width: 150px;
        height: 150px;
        background-image: url("../../../../public//assets/bgdotted.webp");
        background-size: cover;
        bottom: 15%;
        left: 0%;
        z-index: -1;

    }

}