.maingovbox {
    padding: 50px 0px;
    position: relative;
    background-color: #f4f5ff;
}
.govcarddiv{
    z-index: 50;
    position: relative;
    padding-bottom: 30px;
}

.headinggov {
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
    color: whitesmoke;
    
}
.headingdiv {
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to right, #09203f 30%, #537895 100%);
    padding: 15px 100px;
    text-align: start;
    background-position: bottom;
}
.headinggov {
    text-align: start;
    margin-bottom: 7px;
}
.govdesc {
    font-size: 0.88em;
    color: white;
}

@media only screen and (min-width: 768px) {

.govcarddiv {
    display: flex;

    gap: 40px;
    padding: 20px 115px;
    z-index: 50;
    flex-wrap: wrap;
    justify-content: center;
    
}
.govbg1 {

    width: 100%;
    height: 70%;
    background-color: #6BB8B8;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top-left-radius: 70%;
    border-top-right-radius: 30%;
    
    z-index: 0;
    opacity: 0.5;
    
}
.govbg2 {
    width: 100%;
    height: 60%;
    background: linear-gradient(45deg, rgba(245,70,66, 0.35), rgba(8,83,156, 0.35));
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top-left-radius: 70%;
    border-top-right-radius: 30%;
    
    z-index: 0;
    opacity: 0.5;
    
}
.govcarddivin {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

}

@media only screen and (max-width: 768px) {
    .maingovbox {
        padding: 0px 0px 30px 0px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    .govbg1 {

        width: 100%;
        height: 50%;
        background-color: #6BB8B8;
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-top-left-radius: 70%;
        border-top-right-radius: 00%;
        
        z-index: 0;
        opacity: 0.5;
        
    }
    .govbg2 {
        width: 100%;
        height: 40%;
        background: linear-gradient(45deg, rgba(245,70,66, 0.35), rgba(8,83,156, 0.35));
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-top-left-radius: 70%;
        border-top-right-radius: 0%;
        
        z-index: 0;
        opacity: 0.5;
        
    }
    
    .govcarddiv {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 0 auto;
        padding-top: 30px;
        z-index: 10;
    }
    .govbg1 {
        z-index: 0;

    }
    .govbg2{
        z-index: 0;
    }
    .headingdiv {
        margin-bottom: 2px;
        padding: 10px 17px;
    }
    .headinggov {
        padding: 0px;
    }
}

