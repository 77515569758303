@media only screen and (min-width:600px){
  

.galleryWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows:200px;
    
    gap:20px;
    
    max-width: 1100px;
    margin: 0 auto;
    z-index: 90000;
    padding-top: 20px;
  }
  
  .galleryWrap .single {
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 75%);
  }
  .galleryWrap .single:hover {
    box-shadow: 0 3px 10px rgb(0 2px 0 / 100%);
  }
  
  .single:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .single:nth-child(7) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .single:nth-child(13) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 9;
  }
  .single:nth-child(19) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 10;
    grid-row-end: 12;
  }
  .single:nth-child(25) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 13;
    grid-row-end: 15;
  }
  .galleryWrap .single img {
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: rgba(29, 26, 26, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
    font-size: 1.3em;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }
}
@media only screen and (max-width:600px) {
  
  .galleryWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows:140px;
    
    gap:7px;
    
    max-width: 450px;
    margin: 0 auto;
    z-index: 90000;
    padding-top: 20px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .single {
    width: 100%;
  }
  .single:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .single:nth-child(7) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .single:nth-child(13) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 9;
  }
  .single:nth-child(19) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 10;
    grid-row-end: 12;
  }
  .single:nth-child(25) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 13;
    grid-row-end: 15;
  }
  
  
  .galleryWrap .single img {
    width: 100%;
    height: 100%;
  }
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
    font-size: 1.3em;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }
  
}