.homepage {
    width: 100%;
    position: absolute;
    top: 0px;
    
}

.iconconposition {
    position: absolute;
    width: 60px;
    height: 100%;
    
    right: 20px;
    top: 40%;
}
.textcon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
   display: flex;
   
  
   flex-wrap: wrap;
   align-items: center;
   padding-top: 200px;
   padding-bottom: 150px;
   padding-left: 100px;
   z-index: 200;
}
.textconup {
    font-size: 60px;
    font-weight: 900;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    text-align: start;
    color: aliceblue;
    text-shadow: 2px 2px 4px #000000;
   
    
}
.textcondown {
    font-size: 60px;
    font-weight: 900;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    text-align: start;
    color: aliceblue;
    text-shadow: 2px 2px 2px #000000;
    

}
.wlcmmsgcon {
    font-size: 18px;
    font-weight: 500;
    text-align: start;
    width: 100%;
    color: aliceblue;
    padding-bottom: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}



.Chevrondisp {
    display: block;
    padding-left: 20px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Chevronnodisp {
    display: none;
}
.Chevrondisp:hover {
    color: #000000;
}
.msgbox {
    width: 100%;
    height: 600px;
    background-color: none;
    background-size: cover;
    background-position: center;
}
@media only screen and (max-width: 360px) {
    .homepage{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .teams1{
        width: auto;
        flex-wrap: wrap;
        flex: 100%;
        margin: 0 auto;
    }
    .container1{
        width: auto;
        flex-wrap: wrap;
        flex: 100%;
    }
    .textconup {
        font-size: 3.2em;
        font-weight: 1000;
        
    }
    .textcondown {
        font-size: 3.2em;
        font-weight: 1000;
    }
    .wlcmmsgcon {
        font-size: 13px
    }
}

.Parent {
    width:140px;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around
    
  }
  .Child {
    width:100px;
    height:100px;
    
    
  }
  
