@media only screen and (min-width:600px) {
    

.vehiclemaincvcontainer{
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    
    color: aliceblue;
    background-image: linear-gradient(to right, #09203f 0%, #537895 100%);
}
.teampagecvheading {
    
    font-size: 1.1em;
    font-weight: 800;
    text-align: center;
    color: white;
   
}
.vehcilesconcv {
    display: flex;
    width: 85%;

    
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    
    
}
.header1{
    width: 150px;
    text-decoration:underline;
    flex: 100%;
}
}


@media only screen and (max-width:600px) {

    .vehiclemaincvcontainer {
    
      width: 100%;
      height: 100%;
      display:flex;
      flex-wrap: wrap;
      justify-content: space-around;
      background-color: #152E4C!important;
    }
    .teampagecvheading{

       font-size: 1em;
        width: 100%;
       text-align: center;
       color: white;
       padding-top: 8px;
        
    }

    .text1{
    
        padding: 10px;
        text-align: left;
        font-size: 32px;
      
    }
    .vehcilesconcv{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
   .teampagecvheading {
       font-size: 1em;
       font-weight: 700;
       text-align: center;
   }

    
  
 }