@media only screen and (min-width:600px) {
    
    
.maingallerypage {
    padding: 0px 0px 0px 0px;
    background-color: #020202;
}
.maingalleryinside {
    padding: 0px 0px 30px 0px;
    background-color: #020202!important;
    
}
.bgg {
    background-color: #101010;
}
.gallerycompdiv {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    padding: 30px 50px 30px 50px;
    
}
.gallerycomponent {
    width: 350px;
    height: 250px;
    border-radius: 10px;
    
    box-shadow: 2.5px -2.5px teal,5px -5px rgba(16,66,157,1);
    position: relative;
    cursor: pointer;
    
   
}
.lowerbg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background-color: aliceblue;
    filter: opacity(0.7);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;

}
.texts {
    position: absolute;
    z-index: 5;
    bottom: 0px;
    left:0px;
    height: 50px;
    padding-left: 30px;
    padding-top: 10px;
    font-size: 1em;
    font-weight: 800;

}
.textsgall {
    width: 100%;
    height: 40px;
    background-color: aqua;
    position:absolute;
    bottom: 0px;
    left: 0px;
    
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    padding-bottom: 5px;
    cursor: pointer;
    padding-top: 7px;
    
}
.gallerythumbnail {
    color: white;
    font-size: medium;
    font-weight: bold;
    line-height: 1pt;
    margin-bottom: 6px;
    
}
.gallerydesc {
    color: black;
    font-size: small;
    font-weight: lighter;
    
   
}
}
@media only screen and (max-width: 600px) {
    .maingallerypage {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    }
    .maingalleryinside {
        padding: 0px 0px 10px 0px;
    }
    .gallerycomponent {
        width: 80%;
        margin: 0 auto;
        height: 200px;
        box-shadow: 2.5px -2.5px teal,5px -5px rgba(16,66,157,1);
        position: relative;
        border-radius: 5px;
    }
    .gallerycompdiv {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        gap: 30px;
       
        z-index: 10;
        
    }
    .lowerbg {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 50px;
        background-color: aliceblue;
        filter: opacity(0.7);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        z-index: 1;
    
    }
    .texts {
        position: absolute;
        z-index: 15;
        bottom: 0px;
        left:0px;
        height: 50px;
        padding-left: 30px;
        padding-top: 10px;
        font-size: 1em;
        font-weight: 800;
    
    }
    
}