@media screen and (min-width:600px) {
    .container1 {
        width: 100%;
        height: 100%;
        padding-top: 20px;
    }
    .titleabtushmpg {
        font-size: 1.8em;
        font-weight: 500;
        text-align: center;
    }
    .titleabtushmpgdesc {
        font-size: 1em;
        text-align: center;
        color: #aaaaaa;

    }
    .container2{
        width: 100%;
        height: 100%;
        
        position: relative;
        background-color: aliceblue;
        padding: 30px;
        display: flex;
        flex-direction: row;
        
       
    }
    .iframebox1 {
        width: 25%;
       
        padding: 20px;
        border: 2px solid #F7F7F7;
        
    }
    .iframebox2 {
        width: 50%;
        
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 30px;
        padding-bottom: 30px;
        border: 2px solid #F7F7F7;
        
    }
    .notificationbox {
        width: 25%;
        height: 400px;
        background-color: #ffffff;
        padding: 10px 30px 5px 30px;
        overflow-y: scroll;
        
    }
    .notificationsheader {
        font-size: 1em;
        font-weight: 700;
        
    }
    .notbox {
        
        padding-bottom: 5px;
        background-image: linear-gradient(21.47deg,#d0d8f5 -14.07%,#f4f5ff 46.4%,#fff 156.29%);
        margin-bottom: 10px;
        border-radius: 2px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
        
        

    }
    .notboxhead {
        font-size: 0.9em;
        color: crimson;
        line-height: 0.9em;
    }
    .notboxdesc {
        font-weight: 400;
        color: #aaaaaa;
        
        
      
       
    }
    .notboxlink {
        text-decoration: none;
        

    }
    .notboxlink:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    
}
@media  screen and (max-width:600px) {
    .container1 {
        width: 100%;
        height: 100%;
        padding-top: 20px;
    }
    .titleabtushmpg {
        font-size: 1.2em;
        font-weight: 500;
        text-align: center;
    }
    .titleabtushmpgdesc {
        font-size: 0.7em;
        text-align: center;
        color: #aaaaaa;

    }
    .container2 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 20px;
    }
    .iframebox1 {
        width: 100%;
        height: 410px;
        padding: 30px;
        border: 2px solid #F7F7F7;
        
    }
    .iframebox2 {
        width: 100%;
        height: 250px;
        padding: 0px;
        border: 2px solid #F7F7F7;
    }
    .notificationbox {
        width: 100%;
        height: 400px;
        overflow-y: scroll;
        padding: 10px;
        
    }
    .notificationsheader {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .notbox {
        
        padding-bottom: 5px;
        background-image: linear-gradient(21.47deg,#d0d8f5 -14.07%,#f4f5ff 46.4%,#fff 156.29%);
        margin-bottom: 10px;
        border-radius: 2px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    }
    .notboxhead {
        font-size: 0.9em;
        color: crimson;
        line-height: 0.9em;
    }
    .notboxdesc {
        font-weight: 400;
        color: #aaaaaa;
        
        
      
       
    }
    .notboxlink {
        text-decoration: none;
        

    }
    .notboxlink:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    
}



