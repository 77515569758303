:root {
    --stripe-size: 100px;
    --color1: #f5f5f5;
    --color2: #2c3e50;
    --duration: 2s;
  }
  

  
  .stripe {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    width: 100%;
    opacity: 0.85;
    height: 100%;
  }
  .stripe_inner_text {
      font-size: 1rem;
      font-weight: 800;
      color: rgba(255, 255, 255, 0.4);
      z-index: 40;
      margin-bottom: 20px;
  }
  .stripe_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    font-size: 4rem;
    text-align: center;
    font-family: "Anton", sans-serif;
    color: rgba(255, 255, 255, 0.2);
    background: repeating-linear-gradient(45deg, var(--color1) 25%, var(--color1) 50%, var(--color2) 50%, var(--color2) 75%);
    background-size: var(--stripe-size) var(--stripe-size);
    
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-animation: stripeBackgroundPosition var(--duration) linear infinite;
            animation: stripeBackgroundPosition var(--duration) linear infinite;
  }
  .stripe::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + var(--stripe-size));
    height: 100%;
    background: repeating-linear-gradient(45deg, var(--color2) 25%, var(--color2) 50%, var(--color1) 50%, var(--color1) 75%);
    background-size: var(--stripe-size) var(--stripe-size);
    -webkit-animation: stripeTransform var(--duration) linear infinite;
            animation: stripeTransform var(--duration) linear infinite;
  }
  .stripe::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(27, 39, 53, 0) 0%, #090a0f 100%);
  }

  @media only screen and (max-width:600px) {
      .stripe {
          padding-top: 0px;
      }
      
  }
  
  @-webkit-keyframes stripeTransform {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(var(--stripe-size) * -1));
    }
  }
  
  @keyframes stripeTransform {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(var(--stripe-size) * -1));
    }
  }
  @-webkit-keyframes stripeBackgroundPosition {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: calc(var(--stripe-size) * -1) 0;
    }
  }
  @keyframes stripeBackgroundPosition {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: calc(var(--stripe-size) * -1) 0;
    }
  }