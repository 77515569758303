.abtouspagemaincon {
    width: 100%;
    height: 100%;
   
    
    
    
}


.layer {
    padding-top: 25%;
    padding-left: 50%;
    font-size: 3.4em;
    font-weight: 900;
}
.abtuspagedesccon {
    width: 100%;
    height: max-content;
    padding: 20px 200px 30px;
    
    
    
}
.headingtitleabt {
    font-size: 1.8em;
    font-weight: 600;
    text-align: center;
}
.descabtuspage {
    color: rgb(49, 46, 46);
    text-align: justify;
    padding-top: 30px;
    font-weight: 600;
    font-size: 1em;
}
.missions {
    width: 100%;
   
    background-image: linear-gradient(to right, #09203f 0%, #537895 100%);
    
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    
}
.frames {
    width: 70%;
    height: 400px;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 25%);
   
    
}

.logosvismisval {
    width: 110px;
    margin: 0 auto;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    background-position: center;
}
.frametexts {
    padding: 20px;
    color: black;
    font-weight: 500;
}
.headingpic {
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    filter: brightness(50%);
    padding-left: 50%;
    padding-right: 5%;
    padding-top: 22%;
    font-size: 4em;
    font-weight: 800;
    color: white;
    position: relative;
    background-image:linear-gradient(45deg, rgba(0,0,0, 0.65), rgba(8,83,156, 0.75)),url("https://drive.google.com/uc?id=13EA9KOTWf9hoUeS2raSryiuwBB13upMJ");
    z-index: 1;
    position: relative;
    background-position: center;
}
.alllogos {
    width: 100%;
    height: 130px;
   
    margin: 0 auto;
    
    z-index: 1000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 100px 20px 100px;
    background-color: aliceblue;
}
.logo {
    width: 158px;
    height: 100px;

    
    
  
    
    background-size: cover;
    background-repeat: no-repeat;
    
}
.first {
    background-image: url("../../public/assets/saeinternationalwb.png");
    
}
.second {
    background-image: url("../../public/assets/saelogonewwb.png");
    background-position: center;
   

   
}
.third {
    background-image: url("../../public/assets/ZFRlogowb.png");
    background-position: center;

}
.fourth {
    background-image: url("../../public/assets/tgwlogowb.png");
    background-position: center;
}
.colorred {
    color: #178582;
}
@media screen and (max-width:600px){
    .alllogos {
        height: max-content;
        width: 100%;


    }
    .logo {
        width: 160px;
    }
    .abtuspagedesccon {
        padding: 30px!important;
        width: 100%;
        height: max-content;
        background-color: white;
    }
    .layer {
        padding-left: 30%;
        padding-top: 90%;
        font-size: 2em;
    }
    .missions {
        width: 100%;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        

    }
    .headingtitleabt {
        font-size: 1.1em;
    }
    .descabtuspage {
        font-size: 0.9em;
        color: #272727;
    }
    .frames {
        width: 100%;
        margin-bottom: 20px;
        font-size: 0.8em;
    }
    
}