.eventmaindiv {
    padding-top: 0px;
}
.eventcontainers {
    width: 100%;
    height: 100%;
  
    
}
.fb {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)),
    url('https://i.ytimg.com/vi/t-wx0JaYje0/maxresdefault.jpg');
    background-size: cover;
    background-position:top;
    background-repeat: no-repeat;
    background-attachment: fixed;

}
.fbelec {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)),
    url('https://amk-motion.com/sites/default/files/2013-08-03_01-17-50_AMK%20Formula%20Student.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.baja {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)),
    url('https://polycentric.cpp.edu/wp-content/uploads/2017/06/BajaIL.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

}
.effi {
    background:linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)) ,
    url('https://d2t1xqejof9utc.cloudfront.net/screenshots/pics/53588a17804f2c1c707f6e7952f0ad2a/large.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin-bottom: 50px;
}
.eventconhead {
    width: 100%;
    height: 150px;
    background: radial-gradient(circle at center, #d0d8f5 0%, #f4f5ff 46.4%, #fff 156.29%);

    color: black;
    padding-top: 50px;
    
}
.titleventtype {
    text-align: center;
    font-size: 1.8em;
    font-weight: 700;

}
.punchlineeventtype {
    text-align: center;
    color: #178582;
    font-weight: 400;
}
.eventcondesc {
    width: 100%;
    height: max-content;
   
    padding: 100px;
    color: white;
    font-weight: 400;
   
}
.eventlinkbtn {
    background-color: #178582;
    color: white;
    width: 90px;
    height: 35px;
    margin: 0 auto;
    
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eventlinkbtn:hover {
    background-color: #178590;
    transform: scale(1.05);
    cursor: pointer;
}
.btnevent {
    text-decoration: none;
    font-size: 0.8em;
    font-weight: 400;
    color: white;
    text-align: center;
}
.btnevent:hover {
    color: white;
}
.iframeevents {
    background-color: #F4f5ff;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 90%);
}

@media screen and (max-width:480px){
    .eventmaindiv {
        padding-top: 0px;
    }
    .eventcondesc {
        padding: 30px;
    }
    .iframeevents {
        padding-left: 10px;
        padding-right: 10px;
        height: 400px;
        background-color: #F2F2F2;
    }
    .titleventtype {
        font-size: 1.3em;
        font-weight: 500px;
    }
    .punchlineeventtype {
        font-size: 0.88em;
    }
    
}