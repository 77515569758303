

.Card {

    

    width: 300px;
    height: 200px;
    margin: 20px;

    
    
    padding: 10px;
    border-radius: 4px;
    background-image: linear-gradient(21.47deg,#d0d8f5 -14.07%,#f4f5ff 46.4%,#fff 156.29%);
    box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.25);
    z-index: 10;
    

    float: left;

}
.Card:hover {
    cursor: pointer;
    transform: scale(1.05);
}



#des {
       
        font-weight: 500;
        font-size: 0.9em;
        

        color: #000000;
        text-align: center;
        position: relative;
        top: -20px;
       
}
#h {
    
        font-weight: 700;
        font-size: 1em;
       
        /* identical to box height */

        text-align: center;

        color: #000000;

}
.containerm {
    width: 150px;
    height: 80px;
    margin-top: 12px;
    align-items: center;
    margin: 0 auto;

}