.maincontainer {
    padding-top: 0px;
    position: absolute;
    top: 0px;

}
@media only screen and (max-width:600px) {
    .maincontainer {
        padding-top: 100px;
    }
    
}