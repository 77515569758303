.mainsuccessdiv {
    width:58% ;
    background-color: antiquewhite;
    height: 62px;
    display: flex;
    border: 1px solid #F6F6F6;
    border-radius: 10px;
    
    
    z-index: 200000;
    background: rgba(0,0,0,0.8);
   
    backdrop-filter: saturate(180%) blur(10px);
    color: white;
    padding-right: 10px;
    padding-top: 3px;
}
.leftinsidesucdiv {
    width: 14%;
    height: 100%;
    padding: 8px;
}
.rightinsidesucdiv {
    width: 90%;
    height: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
   
    
}
.headingsuctext {
    text-align: left;
    font-family: 'Oswald',sans-serif;
    font-size: 1.1em;
    font-weight: 700;
}
.descsuctext {
    text-align: left;
    
}
@media only screen and (max-width:600px) {
    .mainsuccessdiv {
        width: 100%;
        height: max-content;
    }
    .headingsuctext {
        font-size: 0.95em;

    }
    .descsuctext {
        font-size: 0.8em;
    }
    .leftinsidesucdiv {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}