.mainfaccon {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 50px;
    background-color: #f4f5ff;
    
    
}
.faccontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 80px;
    padding-bottom: 50px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  
    
}
.facmsghead {
    width: 100%;
    
    height: 240px;
    padding: 140px 110px;
    background-image: linear-gradient(to right, #09203f 30%, #537895 100%);
   
    color: antiquewhite;
    background-position: bottom;
}
.facmsghead:hover .fachead {
    -webkit-text-stroke:1px white;
    color: transparent;
}
.fachead {
    font-size: 1.7em;
    font-weight: 800;
    margin-bottom: 9px;
}
.facdesc {
    font-size: 0.9em;
    font-weight: 400;

}
.facmsgboxtitle {
    padding: 0;
    float: left;
    width: 100%;
    font-weight: 600;
    
    font-size: 1.4em;
    
    text-align: center;
    color: black;
    text-shadow: 2px 2px 8px #222;
    font-family: 'Oswald', sans-serif;

}



.facfirstbox {
    width: 40%;
    
    height: max-content;
    padding: 15px;
    z-index: 10;
   


}
.facsecondbox {
    width: 40%;
    z-index: 10;
    
    height: max-content;
    padding: 15px;
    

}
.innerfacfirst {
   
    height: 100%;
}
.innerfacsecond {
   
    height: 100%;
}

.msgcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
   
}
.facimg {
    width: 200px;
    height: 200px;
    position: relative;
    background-color: black;
    margin-bottom: 10px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.45);
}
.facimg:hover .facsocialdisp {
    background:rgba(0, 0, 0, 0.5);
    backdrop-filter:blur(4px);
    cursor: pointer;
    display: flex;
    border-radius: 10px;
   
}
.facsocialdisp {
    
    
    position: absolute;
    display: none;
    padding: 10px;
    top: 45%;
    left: 32%;
    
    
    margin: 0 auto;
    
    gap: 20px;
    justify-content: space-around;
    align-items: center;
    

}
.facsocialnodisp {
    display: none;
}
.icons {
    font-size: 1.2em;
    color: white;
}
.textsfacmsg {
    font-size: 0.95em;
    font-weight: 700;
    padding: 20px;
    text-align: justify;
}
.textsfacmsg span:nth-child(1) {
    position: relative;
    top: -10px;
    left: -5px;
}
.textsfacmsg span:nth-child(2) {
    position: relative;
    top: 5px;
    right: -5px;
}
.facdetails {
    width: 200px;
    margin: 0 auto;
    height: max-content;
    padding: 5px;
}
.facname {
    font-size: 0.98em;
    color: black;
    font-weight: 700;
}
.facdesignation {
    font-size: 0.85em;
    color: #222;
    font-weight: 600;
}
.faccompany {
    font-size: 0.83em;
    color: #222;
    font-weight: 500;
}
@media only screen and (max-width:600px) {
    .mainfaccon {
        padding: 0px;
        background-color: #f4f5ff;
    }
    .faccontainer {
        flex-wrap: wrap;
    }
    .facmsghead {
        width: 100%;
        height: 100px;
        background-image: url("../../../../public/assets/conheadbg.jpg");
        padding: 17px 10px;
        color: antiquewhite;
    }
    .fachead {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 7px;
    }
    .facdesc {
        font-size: 0.82em;
        font-weight: 400;
    
    }
    .facmsgboxtitle {
        font-size: 1em;
        font-weight: 700;
    }
    .facfirstbox{
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }
    .facsecondbox{
        width: 100%;
    }
    .facsocialdisp {
        position: absolute;
        display: none;
        padding: 10px;
        top: 45%;
        left: 32%;
        
        
        margin: 0 auto;
        
        gap: 20px;
        justify-content: space-around;
        align-items: center;
    
    }
    .facsocialnodisp {
        display: none;
    }
    .textsfacmsg {
        padding: 0px;
    }
    
}